<template>
	<b-container fluid>
		<b-row>
			<b-col md="12">
				<iq-card>
					<template v-slot:headerTitle>
						<h4 class="card-title">Lista de Citas</h4>
					</template>
					<template v-slot:headerAction>
						<select class="form-control" v-model="filter" @change="getData">
							<option value="actual">Actual</option>
							<option value="past">Antiguas</option>
							<option value="all">Todo</option>
						</select>
						<b-button
							v-user-can="'crear citas'"
							variant="primary"
							@click="
								showModal('add', {
									patient_id: patientId,
									doctor_id: null,
									service_id: null,
									date: '',
									status: 'AGENDADA',
									notes: '-',
								})
							"
						>
							Agregar
						</b-button>
					</template>
					<template v-slot:body>
						<DataTable
							v-if="appointments"
							:data="appointments"
							:columns="columns"
							:fields-search="['service', 'patient', 'doctor', 'status']"
						>
							<template #rows="{ row, rowIndex }">
								<tr>
									<th scope="row">{{ rowIndex + 1 }}</th>
									<td>#{{ row.code }}</td>
									<td>
										{{ row.date_format }} | {{ row.start_time }}-{{ row.final_time }}
									</td>
									<td>
										{{ row.service }}
									</td>
									<td>
										{{ row.patient }}
									</td>
									<td>
										{{ row.doctor }}
									</td>
									<td>
										<b-badge v-if="row.status === 'AGENDADA'" variant="primary">
											{{ row.status }}
										</b-badge>
										<b-badge v-else-if="row.status === 'REAGENDADA'" variant="secondary">
											{{ row.status }}
										</b-badge>
										<b-badge v-else-if="row.status === 'CANCELADO'" variant="danger">
											{{ row.status }}
										</b-badge>
										<b-badge v-else variant="success">{{ row.status }}</b-badge>
									</td>
									<td>
										<b-button-group>
											<b-button
												v-if="role != 'Paciente'"
												v-user-can="'leer pacientes'"
												variant="success"
												size="sm"
												@click="goToEdit(row.patient_id)"
												v-b-tooltip.hover
												title="Detalle Paciente"
											>
												<i class="ri-file-list-line m-0"></i>
											</b-button>
											<b-button
												v-if="role != 'Paciente'"
												v-user-can="'crear citas'"
												variant="secondary"
												size="sm"
												@click="createTreatment(row)"
												v-b-tooltip.hover
												title="Agregar Tratamiento"
											>
												<i class="ri-add-box-line m-0"></i>
											</b-button>
											<b-button
												v-if="role !== 'Paciente'"
												v-user-can="'actualizar citas'"
												variant="info"
												size="sm"
												@click="changeStatusModal('modal-status', row)"
												v-b-tooltip.hover
												title="Cambiar Estado"
											>
												<i class="ri-arrow-left-fill m-0"></i>
											</b-button>
											<b-button
												v-if="row.status !== 'CANCELADO' && row.status !== 'COMPLETADO'"
												v-user-can="'actualizar citas'"
												variant="warning"
												size="sm"
												@click="showModal('edit', row)"
												v-b-tooltip.hover
												title="Reagendar Cita"
											>
												<i v-if="role === 'Paciente'" class="ri-history-line m-0"></i>
												<i v-else class="ri-ball-pen-fill m-0"></i>
											</b-button>

											<b-button
												v-user-can="'eliminar citas'"
												variant="danger"
												size="sm"
												@click="destroy(row.id)"
												v-b-tooltip.hover
												:title="
													role === 'Paciente' || role === 'Doctor'
														? 'Cancelar Cita'
														: 'Eliminar Cita'
												"
											>
												<i class="ri-delete-bin-line m-0"></i>
											</b-button>
										</b-button-group>
									</td>
								</tr>
							</template>
						</DataTable>
					</template>
				</iq-card>
			</b-col>
		</b-row>

		<ModalForm
			@getData="getData"
			:type="type"
			:data="data"
			:appointments="appointments"
		/>
		<ModalAddTreatment :data="appointmentData" />

		<b-modal id="modal-status" title="Cambiar Estado" no-close-on-backdrop hide-footer>
			<ValidationObserver ref="form" v-slot="{ handleSubmit }">
				<b-form @submit.stop.prevent="handleSubmit(submitStatus)">
					<b-row>
						<b-form-group class="col-md-12" label="Estado:" label-for="status">
							<ValidationProvider name="Estado" rules="required" v-slot="{ errors }">
								<v-select
									:options="filteredStatusOptions"
									v-model="status"
									:class="errors.length > 0 ? ' is-invalid' : ''"
								/>
								<div class="invalid-feedback">
									<span>{{ errors[0] }}</span>
								</div>
							</ValidationProvider>
						</b-form-group>
						<div class="col-md-12 w-100">
							<b-button type="submit" variant="primary" class="float-right">
								Actualizar
							</b-button>
						</div>
					</b-row>
				</b-form>
			</ValidationObserver>
		</b-modal>
	</b-container>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import { xray } from "../../../config/pluginInit";
import api from "./../../../services/api";
import DataTable from "./../../../components/DataTable.vue";
import ModalForm from "./ModalForm.vue";
import ModalAddTreatment from "../treatments/ModalAdd";
import Swal from "sweetalert2";

export default {
	components: {
		ModalForm,
		ModalAddTreatment,
		DataTable,
	},
	data() {
		return {
			status: "AGENDADA",
			data: {},
			type: "add",
			appointments: [],
			appointmentData: {},
			columns: [
				{
					name: "Código",
					field: "code",
					sort: "",
				},
				{
					name: "Fecha",
					field: "date_format",
					sort: "",
				},
				{
					name: "Servicio",
					field: "service",
					sort: "",
				},
				{
					name: "Paciente",
					field: "patient",
					sort: "",
				},
				{
					name: "Doctor",
					field: "doctor",
					sort: "",
				},
				{
					name: "Estado",
					field: "status",
					sort: "",
				},
			],
			filter: "actual",
			appointmentSelected: null,
			statusOptions: ["AGENDADA", "REAGENDADA", "CANCELADO", "COMPLETADO"],
			patientId: null,
			role: null,
		};
	},
	mounted() {
		xray.index();
		this.getData();
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
		if (this.role === "Paciente") {
			this.patientId = user.patient_id;
		}
	},
	computed: {
		filteredStatusOptions() {
			const user = JSON.parse(localStorage.getItem("user"));
			// Si el tipo de usuario es "doctor", mostrar solo 'CANCELADO' y 'COMPLETADO'
			if (user.role === "Doctor") {
				return this.statusOptions.filter(
					(status) => status === "CANCELADO" || status === "COMPLETADO"
				);
			}
			if (user.role === "Paciente") {
				return this.statusOptions.filter((status) => status === "REAGENDADA");
			}
			return this.statusOptions; // Mostrar todas las opciones en otros casos
		},
	},
	methods: {
		async getData() {
			try {
				const { data } = await api.get("/appointments", {
					params: {
						filter: this.filter,
					},
				});
				this.appointments = data.data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		createTreatment(data) {
			this.appointmentData = data;
			this.$bvModal.show("modal-add-treatment");
		},
		showModal(type, data) {
			this.data = {
				id: data.id,
				patient_id: data.patient_id,
				doctor_id: data.doctor_id,
				service_id: data.service_id,
				date: data.date,
				status: data.status,
				notes: data.notes,
			};
			this.type = type;
			this.$bvModal.show("modal-form");
		},
		changeStatusModal(modalName, data) {
			this.appointmentSelected = data.id;
			this.status = data.status;
			this.$bvModal.show(modalName);
		},
		async submitStatus() {
			try {
				await api.patch(`/appointments/${this.appointmentSelected}/update-status`, {
					status: this.status,
				});
				this.$bvModal.hide("modal-status");
				this.getData();
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		destroy(id) {
			Swal.fire({
				icon: "warning",
				title: "Seguro de eliminar",
				showDenyButton: false,
				showCancelButton: true,
				confirmButtonText: "Si",
				cancelButtonText: "Cancelar",
			}).then((result) => {
				if (result.isConfirmed) {
					api
						.delete(`/appointments/${id}`)
						.then((res) => {
							if (res.status === 200) {
								Toast.fire({
									icon: "success",
									title: "Eliminado correctamente",
								});
								this.getData();
							}
						})
						.catch((error) => {
							Toast.fire({
								icon: "error",
								title: error.message,
							});
						});
				}
			});
		},
		goToEdit(patientID) {
			localStorage.setItem("userEdit", patientID);
			this.$router.push({ name: "patient.detail" });
		},
	},
};
</script>
