<template>
	<b-modal
		id="modal-form"
		size="xl"
		:title="type === 'add' ? 'Agregar Cita' : 'Reagendar Cita'"
		no-close-on-backdrop
		hide-footer
	>
		<ValidationObserver ref="form" v-slot="{ handleSubmit }">
			<b-form @submit.stop.prevent="handleSubmit(onSubmit)">
				<p v-if="step === 1">Seleccione servicio:</p>
				<b-row v-if="step === 1">
					<b-col
						sm="6"
						md="6"
						lg="4"
						xl="3"
						v-for="(service, index) in services"
						:key="index"
						@click="nextStep(service.id)"
					>
						<iq-card
							class-name="iq-card-block iq-card-stretch iq-card-height"
							body-class="iq-bg-primary rounded"
						>
							<template v-slot:body>
								<div class="d-flex align-items-center justify-content-between">
									<div class="rounded-circle iq-card-icon">
										<img
											style="width: 100%; object-fit: cover; border-radius: 50%"
											:src="service.icon"
										/>
									</div>
									<div class="text-right">
										<h5>{{ service.description }}</h5>
									</div>
								</div>
							</template>
						</iq-card>
					</b-col>
				</b-row>
				<b-row v-else-if="step === 2">
					<b-form-group
						:class="role === 'Doctor' ? 'col-lg-12 col-md-12' : 'col-lg-6 col-md-12'"
						label="Fecha:"
						label-for="date"
					>
						<ValidationProvider name="Fecha" rules="required" v-slot="{ errors }">
							<input
								class="form-control"
								type="date"
								:min="new Date().toISOString().substr(0, 10)"
								:class="errors.length > 0 ? ' is-invalid' : ''"
								v-model="form.date"
								id="date"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>

					<b-form-group
						v-if="role !== 'Doctor'"
						class="col-lg-6 col-md-12"
						label="Doctor:"
						label-for="doctor_id"
					>
						<ValidationProvider name="Doctor" rules="required" v-slot="{ errors }">
							<v-select
								:options="filteredDoctors"
								label="full_name"
								:reduce="(doctor) => doctor.id"
								v-model="form.doctor_id"
								:class="errors.length > 0 ? ' is-invalid' : ''"
								:disabled="type != 'add'"
							>
								<template slot="option" slot-scope="option">
									<img
										class="mr-2"
										style="border-radius: 50%"
										height="50px"
										:src="option.photo"
									/>
									{{ option.full_name }}
								</template>
							</v-select>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<b-col md="12">
						<p>Horarios Disponibles:</p>
					</b-col>
					<template v-if="selectedTimeSlots.length > 0">
						<b-col
							md="6"
							lg="4"
							sm="6"
							v-for="(time, index) in selectedTimeSlots"
							:key="index"
							@click="nextStep(time)"
						>
							<b-alert :show="true" class="text-white bg-primary">
								<div class="iq-alert-text">
									{{ time.start_time }} - {{ time.final_time }}
								</div>
							</b-alert>
						</b-col>
					</template>
					<template v-else>
						<b-col v-if="form.date && form.doctor_id" md="12" class="text-center">
							<b>NO HAY ATENCIÓN ESTE DÍA</b>
						</b-col>
					</template>
					<div class="col-md-12 w-100">
						<b-button
							v-if="type === 'add'"
							variant="primary"
							class="float-left"
							@click="prevStep()"
						>
							Regresar
						</b-button>
					</div>
				</b-row>
				<b-row v-else>
					<b-form-group
						v-if="role != 'Paciente'"
						class="col-md-12"
						label="Paciente:"
						label-for="patient_id"
					>
						<ValidationProvider name="Paciente" rules="required" v-slot="{ errors }">
							<v-select
								:options="patients"
								label="full_name"
								:reduce="(service) => service.id"
								v-model="form.patient_id"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<b-form-group class="col-md-6" label="Notas:" label-for="notes">
						<ValidationProvider name="Notas" rules="required" v-slot="{ errors }">
							<b-form-textarea
								id="notes"
								v-model="form.notes"
								placeholder="Enter something..."
								rows="2"
								max-rows="4"
								:class="errors.length > 0 ? ' is-invalid' : ''"
							/>
							<div class="invalid-feedback">
								<span>{{ errors[0] }}</span>
							</div>
						</ValidationProvider>
					</b-form-group>
					<div class="col-md-6 w-100">
						<h4>Resumen de Cita:</h4>
						<p>
							<b>Fecha:</b> {{ data.date.split("-").reverse().join("/") }} <br />
							<b>Hora:</b> {{ data.start_time }}-{{ data.final_time }} <br />
							<b>Doctor:</b> {{ filterDoctor.full_name }} <br />
							<b>Servicio:</b> {{ serviceSelected.description }}
						</p>
					</div>
					<div class="col-md-12 w-100">
						<b-button variant="primary" class="float-left" @click="prevStep()">
							Regresar
						</b-button>
						<b-button type="submit" variant="primary" class="float-right">
							{{ type === "add" ? "Agendar" : "Actualizar" }}
						</b-button>
					</div>
				</b-row>
			</b-form>
		</ValidationObserver>
	</b-modal>
</template>

<script>
import { Toast } from "./../../../mixins/toast";
import api from "./../../../services/api";
import Swal from "sweetalert2";
import constants from "../../../config/constant";

export default {
	props: {
		type: {
			type: String,
			required: true,
			default: "add",
		},
		data: {
			type: Object,
			required: false,
		},
		appointments: {
			type: Array,
		},
	},
	data() {
		return {
			form: {
				patient_id: null,
				doctor_id: null,
				service_id: null,
				date: "",
				status: "AGENDADA",
				notes: "",
			},
			doctors: [],
			patients: [],
			services: [],
			step: 1,
			daySelected: null,
			role: null,
		};
	},
	mounted() {
		let user = JSON.parse(localStorage.getItem("user"));
		this.role = user.role;
		this.getDoctors();
		this.getPatients();
		this.getServices();
	},
	watch: {
		data: function (newValue, oldValue) {
			if (newValue.service_id === null) {
				this.step = 1;
			} else {
				this.step = 2;
			}
			this.form = newValue;
			if (this.role === "Doctor") {
				let user = JSON.parse(localStorage.getItem("user"));
				this.form.doctor_id = user.doctor_id;
			}
			const today = new Date();
			const year = today.getFullYear();
			const month = String(today.getMonth() + 1).padStart(2, "0");
			const day = String(today.getDate()).padStart(2, "0");
			this.form.date = `${year}-${month}-${day}`;
			this.handleDate();
		},
	},
	computed: {
		filteredDoctors() {
			return this.doctors.filter((doctor) =>
				doctor.services.includes(this.form.service_id)
			);
		},
		serviceSelected() {
			return this.services.find((service) => service.id === this.form.service_id);
		},
		filterDoctor() {
			return this.doctors.find((doctor) => doctor.id === this.form.doctor_id);
		},
		filterSchedule() {
			if (
				this.filterDoctor &&
				this.filterDoctor.schedules &&
				this.filterDoctor.schedules.length > 0
			) {
				return (
					this.filterDoctor.schedules.find(
						(schedule) => schedule.day === this.daySelected
					) || {}
				);
			} else {
				return {};
			}
		},
		separateTimesBySlots() {
			return this.fragmentTimeArray(
				this.filterSchedule.attention ? this.filterSchedule.times : [],
				this.filterDoctor?.slots_time ?? 30
			);
		},
		bookedTimes() {
			return this.appointments
				.filter((appointment) => appointment.date === this.form.date)
				.filter((appointment) => appointment.doctor_id === this.form.doctor_id)
				.map((appointment) => {
					const [hours, minutes] = appointment.start_time.split(":");
					return `${hours}:${minutes}:00`;
				});
		},
		availableTimes() {
			return this.separateTimesBySlots.filter(
				(timeSlot) => !this.isTimeSlotBooked(timeSlot)
			);
		},
		filteredTimeSlots() {
			const currentTime = new Date();
			const formattedCurrentTime = `${currentTime.getHours()}:${currentTime.getMinutes()}:${currentTime.getSeconds()}`;

			return this.availableTimes.filter(
				(timeSlot) => timeSlot.start_time >= formattedCurrentTime
			);
		},
		selectedTimeSlots() {
			const selectedDate = this.form.date;
			const currentDate = new Date().toISOString().split("T")[0];

			const timeSlotsToUse =
				selectedDate === currentDate ? this.filteredTimeSlots : this.availableTimes;

			return timeSlotsToUse;
		},
	},
	methods: {
		handleDate() {
			const dateObj = new Date(this.form.date);
			const dayOfWeek = dateObj.getDay();
			const dayNames = [
				"Lunes",
				"Martes",
				"Miércoles",
				"Jueves",
				"Viernes",
				"Sábado",
				"Domingo",
			];
			const dayName = dayNames[dayOfWeek];
			this.daySelected = dayName;
		},
		handleService() {
			this.form.doctor_id = null;
		},
		async getDoctors() {
			try {
				let { data } = await api.get("/lists/doctors");
				data = data.map((doctor) => {
					return {
						...doctor,
						photo: doctor.photo.includes("jpg") ? doctor.photo : constants.defaultImage
					};
				});

				console.log(data)
				this.doctors = data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getPatients() {
			try {
				const { data } = await api.get("/lists/patients");
				this.patients = data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async getServices() {
			try {
				const { data } = await api.get("/lists/services");
				this.services = data;
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		onSubmit() {
			if (this.type === "add") {
				this.saveData();
			} else {
				this.updateData();
			}
		},
		async saveData() {
			try {
				const result = await Swal.fire({
					icon: "warning",
					title: "Seguro de guardar",
					showCancelButton: true,
					confirmButtonText: "Si",
					cancelButtonText: "Cancelar",
				});

				if (!result.isConfirmed) {
					return;
				}

				await api.post("/appointments", this.form);

				this.form = {
					patient_id: null,
					doctor_id: null,
					service_id: null,
					date: "",
					status: "AGENDADA",
					notes: "",
				};

				this.$bvModal.hide("modal-form");
				this.$emit("getData");
			} catch (error) {
				Toast.fire({
					icon: "error",
					title: "Error",
				});
			}
		},
		async updateData() {
			api
				.put(`/appointments/${this.data.id}`, this.form)
				.then(() => {
					Toast.fire({
						icon: "success",
						title: "Actualizado Correctamente",
					});
					this.$bvModal.hide("modal-form");
					this.$emit("getData");
				})
				.catch(() => {
					Toast.fire({
						icon: "error",
						title: "Error",
					});
				});
		},
		nextStep(data) {
			switch (this.step) {
				case 1:
					this.form.service_id = data;
					break;
				case 2:
					this.form.start_time = data.start_time;
					this.form.final_time = data.final_time;
					break;
				default:
					this.onSubmit();
					break;
			}
			this.step++;
		},
		prevStep() {
			this.step -= 1;
		},
		timeToMinutes(time) {
			const [hours, minutes, seconds] = time.split(":").map(Number);
			return hours * 60 + minutes + seconds / 60;
		},
		fragmentTimeArray(originalArray, minutesPerRange) {
			const fragmentedArray = [];

			if (originalArray) {
				originalArray.forEach(({ start_time, final_time }) => {
					const startMinutes = this.timeToMinutes(start_time);
					const finalMinutes = this.timeToMinutes(final_time);

					for (
						let currentTime = startMinutes;
						currentTime < finalMinutes;
						currentTime += minutesPerRange
					) {
						const nextTime = Math.min(currentTime + minutesPerRange, finalMinutes);

						fragmentedArray.push({
							start_time: new Date(
								0,
								0,
								0,
								Math.floor(currentTime / 60),
								currentTime % 60
							)
								.toTimeString()
								.slice(0, 8),
							final_time: new Date(0, 0, 0, Math.floor(nextTime / 60), nextTime % 60)
								.toTimeString()
								.slice(0, 8),
						});
					}
				});
			}
			return fragmentedArray;
		},
		isTimeSlotBooked(timeSlot) {
			return this.bookedTimes.some((bookedTime) =>
				timeSlot.start_time.startsWith(bookedTime)
			);
		},
	},
};
</script>
